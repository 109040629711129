const Navbar = () => {
  return (
    <nav className="flex items-center fixed bg-secondary top-0 left-0 justify-between flex-wrap transparent p-6 w-full" style={{
    
    }}>
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <a href="/">
          <img src="/tslogo.svg" alt="Logo" width={200} height={200} />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
