const Button = ({ callback, text, onlyBorder, forwardRef, bg, disabled }) => {
  if (onlyBorder)
    return (
      <button
        ref={forwardRef}
        onClick={() => {
          if (callback) callback();
        }}
        className="bg-transparent w-64 hover:bg-blue-700 border-primary-100 hover:bg-primary-200 border-2 text-white font-bold py-2 px-12 rounded-full"
      >
        {text}
      </button>
    );
  return (
    <button
      ref={forwardRef}
      onClick={() => {
        if (callback) callback();
      }}
      disabled={disabled ? disabled : false}
      className="bg-primary-100 w-64 hover:bg-primary-200 text-white font-bold py-2 px-12 rounded-full"
      style={{
        background: bg ? bg : "",
      }}
    >
      {text}
    </button>
  );
};

export default Button;
