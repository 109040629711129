/* eslint-disable */
import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "../components/Confetti";
import { AnimatePresence, motion } from "framer-motion";

const ViewOne = () => {
  return (
    <div className="flex flex-col items-center w-5/5 justify-end">
      <div className="flex items-ceneter justify-center mt-12">
        <div className="w-1/2 flex-col flex items-center h-full" style={{}}>
          <p
            className=" text-center my-6 text-primary-200"
            style={{
              maxWidth: "30vw",
            }}
          >
            {" "}
            Reformulate the given sentence such that it contains as few occurrences of the character E as possible.
          </p>

          <div className="w-96 h-full bg-textbox border-primary-100 flex flex-col justify-around items-around p-6 rounded-xl">
            <div className="flex flex-col justify-around items-center">
              <span>Wait for the game to start...</span>
              <div class="balls mt-4">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 flex justify-end items-center">
          <img src="tssymbol.webp" alt="Logo" width="70%" height={250} />
        </div>
      </div>
    </div>
  );
};

const ViewTwo = ({
  timeLimit,
  gamePhrase,
  getECount,
  playerPhrase,
  setPlayerPhrase,
  handleECount,
  allowedCharacters,
}) => {

  const [characterCount, setCharacterCount] = useState(0);
  const MAX_CHARACTER_COUNT = allowedCharacters;
  const shadowRef = useRef(null);
  const intervalRef = useRef();
  const contentEditableRef = useRef(null);

  useEffect(() => {
    contentEditableRef.current.focus();
  }, []);

  const handleInput = (e) => {
    const textContent = e.currentTarget.innerHTML;
    const highlightedText = textContent
      .split("")
      .map((char) =>
        char.toLowerCase() === "e"
          ? `<span class="highlighted-e">${char}</span>`
          : char,
      )
      .join("");

    handleECount(textContent);
    setCharacterCount(e.currentTarget.textContent.length);
    shadowRef.current.innerHTML = highlightedText;
    setPlayerPhrase(e.currentTarget.textContent);
  };

  return (
    <div className="flex flex-col items-center w-5/5 justify-end">
      <div>
        {" "}
        <div className="bg-primary-100 rounded-full flex justify-center items-center w-32 h-12 text-2xl" style={{
          borderBottom: "1px solid white"
        }}>
          {timeLimit}
        </div>
      </div>

      <div>
        <div className="flex justify-between py-4">
          <span className="bg-primary-100 rounded-full text-4xl py-2 px-6 btn-grad">E: {getECount(playerPhrase)}</span>
          <span

            className="bg-primary-100 text-4xl rounded-full py-2 px-6 btn-grad" style={{}}
          >
            {playerPhrase.length}/{allowedCharacters}
          </span>
        </div>
        <div style={{ position: "relative" }}>
          <div
            ref={shadowRef}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              overflow: "hidden",
              width: "70vw",
              height: "55vh",
              background: "transparent",
              userSelect: "none",
              pointerEvents: "none",
              color: "white",
              zIndex: "0",
              lineHeight: "4.5rem",
              borderColor: "transparent",
              border: "8px solid rgba(255,255,255,0.1)",
            }}
            className="text-6xl p-12   border-8  rounded-2xl"
          />
          <div
            ref={contentEditableRef}
            contentEditable="true"
            placeholder="Type here"
            onInput={handleInput}
            id="contentEditablePlayer"
            className=" z-10 bg-textbox border-8 rounded-2xl text-6xl p-12 outline-none overflow-hidden"
            style={{
              width: "70vw",
              height: "55vh",
              backgroundColor: "transparent",
              color: "white",
              zIndex: "9999",
              lineHeight: "4.5rem",
              borderColor: "transparent",
            }}
          />
        </div>
        <h6 className="text-xs py-3"></h6>
      </div>
    </div>
  );
};




const RoomComponent = ({ roomDetails, socket }) => {
  const [eCount, setECount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [gamePhrase, setGamePhrase] = useState("");
  const [playerPhrase, setPlayerPhrase] = useState("");
  const [aiPhrase, setAiPhrase] = useState("");
  const [phaseOne, setPhaseOne] = useState(true);
  const [timeLimit, setTimeLimit] = useState("");
  const [allowedCharacters, setAllowedCharacters] = useState(0);

  const [view, setView] = useState(1);
  const intervalRef = useRef();
  const schoolPrideRef = useRef();

  const handleECount = (text) => {
    const finalCount = text.split("e").length - 1;
    const finalCount2 = text.split("E").length - 1;
    setECount(finalCount + finalCount2);
  };

  const getECount = (text, winner) => {
    const finalCount = text.split("e").length - 1;
    const finalCount2 = text.split("E").length - 1;

    return finalCount + finalCount2;
  };

  const handleCountDown = (time) => {
    let count = time;
    const id = setInterval(() => {
      count--;
      setTimeLimit(count);
      if (count === 0) {
        clearInterval(intervalRef.current);
      }
    }, 1000);
    intervalRef.current = id;
  };

  const highlightE = (text) => {
    console.log(text);
    return text.replace(
      /e/gi,
      (match) => `<span class="highlighted-e">${match}</span>`,
    );
  };

  useEffect(() => {
    const handleGameStarted = (data) => {
      setView(2);
      setGamePhrase(data.phrase.phrase);
      setAllowedCharacters(data.phrase.allowedCharacters);
      handleCountDown(data.phrase.time);
    };

    const handleGameStoppedOrReset = () => {
      schoolPrideRef.current.stopAnimation();
      setView(1);
      clearInterval(intervalRef.current);
      setGamePhrase("");
      setECount(0);
      setCharacterCount(0);
      setTimeLimit("");
      setTimeout(() => window.location.reload(), 1000);
    };

    socket.on("game_started", handleGameStarted);
    socket.on("game_stopped", handleGameStoppedOrReset);
    socket.on("game_reset", handleGameStoppedOrReset);
    socket.on("ai_phrase_recieve", (phrase) => {
      setAiPhrase(phrase[0]);
    });

    return () => {
      socket.off("game_started", handleGameStarted);
      socket.off("game_stopped", handleGameStoppedOrReset);
      socket.off("game_reset", handleGameStoppedOrReset);
      socket.off("ai_phrase_recieve", (phrase) => {
        setAiPhrase(phrase[0]);
      });
    };
  }, []);

  useEffect(() => {
    if (timeLimit === 0) {
      const elem = document.getElementById("contentEditablePlayer");
      if (elem) document.getElementById("contentEditablePlayer").setAttribute("contenteditable", "false");

    }
  }, [timeLimit]);

  const GUIState = useMemo(() => {
    const views = new Map([
      [1, <ViewOne />],
      [
        2,
        <ViewTwo
          timeLimit={timeLimit}
          gamePhrase={gamePhrase}
          getECount={getECount}
          playerPhrase={playerPhrase}
          setPlayerPhrase={setPlayerPhrase}
          handleECount={handleECount}
          allowedCharacters={allowedCharacters}
        />,
      ],
    ]);

    return views.get(view);
  }, [view, timeLimit, gamePhrase]);
  return (
    <motion.div>
      <Confetti ref={schoolPrideRef} />
      <AnimatePresence>
        <motion.div
          layout
          key={view}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.2 }} // Customize duration as needed
        >
          {GUIState}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default function Home({ socket }) {
  const navigate = useNavigate();
  const [roomDetails, setRoomDetails] = useState({
    roomID: "id",
    name: "",
  });
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const reconnect = () => {
      if (!isConnected) {
        socket.emit("checkRoom", (isRoomOpen) => {
          if (isRoomOpen) {
            socket.emit("joinRoom");
          }
        });
      }
    };

    socket.on("joinedRoom", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected, attempting to reconnect...");
      setIsConnected(false);
      reconnect();
    });

    const checkRoomInterval = setInterval(reconnect, 1000);

    return () => {
      clearInterval(checkRoomInterval);
      socket.off("disconnect");
      socket.off("joinedRoom");
    };
  }, []);

  return (
    <>
      <main
        className="flex h-full flex-col items-center justify-center p-12 bg-secondary overflow-hidden"
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden !important",
        }}
      >
        {roomDetails && (
          <RoomComponent roomDetails={roomDetails} socket={socket} />
        )}
        <div
          style={{
            position: "absolute",
            height: "1000px",
            width: "50px",
            opacity: "0.08",
            background: "hsl(347, 100%, 57%)",
            borderRadius: "100%",
            boxShadow: "0px 100px 100px 200px hsl(347, 100%, 57%)",
            rotate: "-45deg",
            zIndex: "10",
          }}
        ></div>
      </main>

    </>
  );
}
