import "./App.css";
import Navbar from "./components/Navbar";
import GameMaster from "./pages/gameMaster";
import Home from "./pages/home";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { socket } from "./socket";

const App = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);

  return (
    <>
      <Navbar />
      {/* <ConnectionState isConnected={isConnected} />
      <Events events={fooEvents} />
      <ConnectionManager />
      <MyForm /> */}
      <Router>
        {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" exact element={<Home socket={socket} />} />
          <Route path="/gamemaster" element={<GameMaster socket={socket} />} />
        </Routes>
      </Router>
      {/* <Footer/> */}
    </>
  );
};

export default App;
